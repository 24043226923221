import React from "react"

const HomeSolutions = props => {
  console.log(props)

  const featureLists = props.feature.featureHighlights.map(featureHighlight => (
    <div
      className="col-lg-6 col-md-12 pt-3 feature-highlight"
      key={featureHighlight}
    >
      <img src="/static/svgs/chandelier copy.svg" alt="*" />
      <span className="pl-lg-2 pl-md-0 feature-text">{featureHighlight}</span>
    </div>
  ))
  return (
    <div
      className="py-lg-2 py-md-0 pt-lg-3 pt-md-0 feature-desc"
      id={props.feature.featureName}
    >
      <section className="blue-area">
        <img
          src={props.feature.featureIcon}
          alt={props.feature.featureName}
          width="80px"
          className="img-fluid blue-area-icon"
        />
      </section>
      <h3 className="feature-name font-16" className="blue-area-title">
        {props.feature.featureName}
      </h3>
      <div className="home-sol-pd-rt">
        <p
          className="font-14 featureDesc"
          dangerouslySetInnerHTML={{ __html: props.feature.featureDesc }}
        ></p>
        <div className="row font-14 featureList">{featureLists}</div>
      </div>
      {/* <button className="btn learn-more-button font-14">Learn more</button> */}
    </div>
  )
}

export default HomeSolutions
