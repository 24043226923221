import React, { Component } from "react"
import { Link } from "gatsby"
import HomeSolutions from "../components/HomeSolutions"
import { featureHighlights } from "../config"
import Layout from "../components/layout"
import SEO from "../components/seo"
import $ from "jquery"
import "../styles/solutions.scss"
import redCross from "../assets/images/red-cross.png"
import greenCheckBox from "../assets/images/green-checkbox.png"

const features = featureHighlights.map(feature => (
  <HomeSolutions key={feature.featureName} feature={feature} />
))

const colContent = [
  {
    featureName : 'Plug & play',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox,
  },
  {
    featureName : '24x7 plant monitoring',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Custom dashboards and reports',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Machine to Machine Communication (M2M)',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Analytical Visibility of the production process',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Real-time quality check',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Secure access',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Alerts & real-time notifications:',
    type : 'subHead'
  },
  {
    featureName : 'Whatsapp & SMS Notifications',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Email Notifications',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Telegram & Automated Calls',
    isEdge : greenCheckBox,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Remote access',
    isEdge : redCross,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Cloud integration',
    isEdge : redCross,
    isEagle : greenCheckBox,
    isAcute : greenCheckBox
  },
  {
    featureName : 'AI-based predictive capabilities:',
    type : 'subHead'
  },
  {
    featureName : 'Auto-detection of shutdowns',
    isEdge : redCross,
    isEagle : redCross,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Failure prediction',
    isEdge : redCross,
    isEagle : redCross,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Auto-connect to machines of different makes and models',
    isEdge : redCross,
    isEagle : redCross,
    isAcute : greenCheckBox
  },
  {
    featureName : 'Advanced analytics*',
    isEdge : redCross,
    isEagle : redCross,
    isAcute : greenCheckBox
  },
]


const ourSolutionData = [
  {}
]


const faqData = [
  {
    faqQue : "How can I start with the IoT deployments in my plant?",
    isList : true,
    listTitle : "All of our industrial IoT devices are pre-configured and ready to use. The following is our procedure:",
    content : ["Provide us with a detailed description of your requirements","If necessary, we will come to your facilities.","Add the metrics you want to the dashboard.","Our industrial IoT solutions take less than 10 minutes to set up.","We ensure that everything works properly for you.","We provide extensive training to your employees on how to utilize our IoT solutions."]
  },
  {
    faqQue : "What is the difference between solutions and products?",
    content : "Technically there is no difference. We are a product company rather than a service firm. However, to solve client concerns, we frequently require numerous goods. As a result, we have grouped our industrial IoT devices into three distinct IoT solutions. You can find out more about the solutions here."
  },
  {
    faqQue : "I have already figured out the key areas in my organization. Now, how can I deploy your products?",
    content : "Our products page will explain what they are and how they may benefit you. Once you've told us what you're searching for, we'll show you how to install our solutions on your own. We, on the other hand, would be delighted to install them for you."
  },
  {
    faqQue : "I want to deploy your products within the plant premises. Can I use any of your products for the same?",
    content : "Yes. We have bundled our products into three distinct solutions. You can find out more about them here. Feel free to pick the one that best suits your business needs. However, if you find that our existing solutions are not suitable for your organization, we are more than happy to customize a solution to fit your business needs. You can contact us here."
  },
  {
    faqQue : "I have all the data points, and KPIs identified. I need to apply data science and AI algorithms to that. Can I do that?",
    content : "Absolutely! Lucrys is our AI-based platform that identifies hidden patterns and finds correlations concerning your processes. We would be happy to demonstrate how it can benefit you. (Call to action)"
  },
  {
    faqQue : "What key points do I have to share before I install your products?",
    content : "Make & Model of the PLCs/DCS/SCADA, OS version of the systems in which they are installed (for SCADA & DCS), Communication Ports available on the PLC (Ethernet or RS232 or RS485), Latest Programme backup of the PLC (Identification of tags and addresses), List of Inputs and Outputs available"
  },
  {
    faqQue : "Can the products be installed and deployed by our plant engineers or operators?",
    content : "Yes! All our products feature plug-and-play. All you need is 10 minutes, and you are good to go."
  },
]

class Solutions extends Component {
  componentDidMount() {
    $(document).ready(function() {
      $(window).scroll(function() {
        var positiontop = $(document).scrollTop()
        if (positiontop > 900 && positiontop < 1650) {
          $(".solutions-edge").addClass("solutions1")
          $(".solutions-edge").removeClass("sol-hide")
          $(".solutions-eagle").addClass("sol-hide")
        }
        if (positiontop > 1650 && positiontop < 2150) {
          $(".solutions-eagle").removeClass("sol-hide")
          $(".solutions-eagle").addClass("solutions2")
          $(".solutions-edge").addClass("sol-hide")
          $(".solutions-acute").addClass("sol-hide")
        }
        if (positiontop > 2150 && positiontop < 3000) {
          $(".solutions-acute").removeClass("sol-hide")
          $(".solutions-acute").addClass("solutions3")
          $(".solutions-eagle").addClass("sol-hide")
        }
      })
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Smart Factory Solutions|Machstatz" />
        <div className="container">
          <h1 className="col-12  sol-fold">
            Start the Digital Transformation of your Factory with our Industrial IOT Solutions. <br />
            Leverage Ai/ML Data Analytics that suits your organization’s structure and needs.
          </h1>
          <div className="table-border d-none d-lg-block shadow-sm">
            <table className="w-100 desktop-table">
              <colgroup>
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
              </colgroup>
              <thead>
                <tr
                  className="text-center"
                  style={{ backgroundColor: "#FAFAFA" }}
                >
                  <th className="t-heading-sol pl-2">Features</th>
                  <th className="t-heading-sol">Edge</th>
                  <th className="t-heading-sol">Eagle eye</th>
                  <th className="t-heading-sol">Acute</th>
                </tr>
              </thead>

              <tbody className="mobile-table">
                {colContent.map((item)=>{
                  return (<React.Fragment>{item.type == "subHead" ? <tr style={{ backgroundColor: "rgba(255, 250, 250, 0.966)" }}>
                  <td
                    colspan="4"
                    className="table-heading"
                    style={{ textAlign: "left" }}
                  >
                    {item.featureName}
                  </td>
                </tr> : <tr>
                  <td className="t-data-sol" style={{ textAlign: "left" }}>
                    {item.featureName}
                  </td>
                  <td>
                    <img
                      src={item.isEdge}
                      width="14"
                      height="14"
                    />
                  </td>
                  <td>
                    <img
                      src={item.isEagle}
                      width="14"
                      height="14"
                    />
                  </td>
                  <td>
                    <img
                      src={item.isAcute}
                      width="14"
                      height="14"
                    />
                  </td>
                </tr>}</React.Fragment>)
                })}
                <tr>
                  <td className="t-data-sol" style={{ textAlign: "left" }}>
                    Products used
                  </td>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateway</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateway</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateway</li>
                      <li>Vibrational Analysis</li>
                      <li>Acute</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="mobile-table" className="table-border d-lg-none shadow-sm">
            <table className="text-center">
              <colgroup>
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
                <col style={{ backgroundColor: "rgba(226, 245, 250,0.2)" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Edge</th>
                  <th>Eagle Eye</th>
                  <th>Acute</th>
                </tr>
              </thead>
              <tbody>
                {colContent.map((item)=>{
                  return (<React.Fragment>{item.type == 'subHead' ? <tr>
                  <td
                    colSpan="3"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                      backgroundColor: "rgba(255, 250, 250, 0.966)",
                      fontWeight: "500",
                    }}
                    className="table-heading"
                  >
                    {item.featureName}
                  </td>
                </tr> : <tr>
                  <td>{item.isEdge == greenCheckBox ? item.featureName : <img src={redCross} width="14" height="14" />}</td>
                  <td>{item.isEagle == greenCheckBox ? item.featureName : <img src={redCross} width="14" height="14" />}</td>
                  <td>{item.isAcute == greenCheckBox ? item.featureName : <img src={redCross} width="14" height="14" />}</td>
                </tr>}</React.Fragment>)
                })}
               
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      fontSize: "25px",
                      padding: "20px",
                      backgroundColor: "rgba(255, 250, 250, 0.966)",
                      fontWeight: "500",
                    }}
                    className="table-heading"
                  >
                    Products Used:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateways</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateways</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Industrialized Integrated Gateways</li>
                      <li>VibrationaAnalysis</li>
                      <li>Acute</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="font-14 pl-lg-2 pl-md-1">
            *Advanced analytics such as causal relations, correlations, hidden
            patterns, etc.
          </p>
          <br />
          <br />

          <section className="custom-container mr-0 py-4" id="our-solutions">
            <div className="row m-0 position-relative">
              <div
                className="col-md-12 col-lg-4 pl-0 sol-pos"
                style={{ top: "100px", left: "0px", height: "fit-content" }}
              >
                <h2 className="home-side-heading pt-5 p-top-choose pl-lg-3 pl-md-0">
                  Our Solutions
                </h2>
                <section className="d-none d-lg-block px-lg-3 px-md-0">
                  <span className="solutions-edge">
                    <p className="solution-desc py-3 m-0">
                        We provide smart factory solutions to manufacturing companies.
                        Our products optimize the production process; help companies monitor
                        key performance indicators (KPI) and failure predictions using real-time data analytics.
                    </p>
                  </span>
                  <span className="solutions-eagle">
                    <p className="solution-desc py-3 m-0">
                      <span
                        style={{
                          fontFamily: "Avenir Roman",
                          fontWeight: "bold",
                        }}
                      >
                        What about data manipulation risks?
                      </span>
                      <br />
                      <br />
                      Now access, view and download data directly from the
                      machines, via our IoT products. Never depend on anyone for
                      production or factory reports again. Goodbye data
                      manipulation!
                    </p>
                  </span>
                  <span className="solutions-acute">
                    <p className="solution-desc py-3 m-0">
                      <span
                        style={{
                          fontFamily: "Avenir Roman",
                          fontWeight: "bold",
                        }}
                      >
                        Are you worried about untimely machine breakdown costs?
                      </span>
                      <br />
                      <br />
                      Accurately predict machine breakdown, maintenance
                      requirements, production and much more, using our
                      Artificial Intelligence products. Now fix issues way
                      before they hurt your business!
                    </p>
                  </span>
                </section>
                <section className="d-sm-block d-lg-none">
                  <p className="solution-desc m-0">
                    We provide industrial automation products to machine
                    intensive enterprises. Our products automate the production
                    process and help you remotely monitor progress and machine
                    health.
                  </p>
                  <p className="solution-desc m-0 pt-3">
                    <span
                      className="solution-desc"
                      style={{ fontFamily: "Avenir Roman", fontWeight: "bold" }}
                    >
                      What about data manipulation risks?
                    </span>
                    <br />
                    <br />
                    Now access, view and download data directly from the
                    machines, via our IoT products. Never depend on anyone for
                    production or factory reports again. Goodbye data
                    manipulation!
                  </p>
                  <p className="solution-desc m-0 pt-3 mb-3">
                    <span
                      className="solution-desc"
                      style={{ fontFamily: "Avenir Roman", fontWeight: "bold" }}
                    >
                      Are you worried about untimely machine breakdown costs?
                    </span>
                    <br />
                    <br />
                    Accurately predict machine breakdown, maintenance
                    requirements, production and much more, using our Artificial
                    Intelligence products. Now fix issues way before they hurt
                    your business!
                  </p>
                </section>
                <section className="reqst-sol-div pl-lg-4 pl-md-0">
                  <Link to="#lets-talk" className="p-0">
                    <button type="button" className="request-button-sol">
                      Request Demo
                    </button>
                  </Link>
                </section>
              </div>
              <div
                className="col-lg-8 col-md-12 home solution"
                id="home-solutions"
              >
                {features}
              </div>
            </div>
          </section>

          {/* <section className="p-top-home">
        <h2 className="sol-title">Our Process</h2>
        <div className="auto-overflow pt-lg-5 pt-md-0">
          <img src="/static/images/process.png" className="process-diagram" />
        </div>
      </section> */}
          <section className="freq-qsn-pd p-top-home">
            <section className="pt-lg-5 pb-lg-5 pt-md-0">
              <h2 className="sol-title">Frequently Asked Questions</h2>
              <div className="faq-scroller row pb-lg-5">
                {faqData.map((item)=>{
                  return (<div className="col-md-6 col-lg-4 pr-lg-4 px-md-3 pt-lg-5 speech-bubble-div">{item.isList == true ? <div className="speech-bubble">
                  <h6 className="head-sol text-justify">
                    {item.faqQue}
                  </h6>
                  <p className="questions-sol text-justify">
                    {item.listTitle}
                    {item.content.map((item)=>{
                      return (<span className="d-block text-justify">
                      - {item}
                    </span>)
                    })}
                  </p>
                </div> : <div className="speech-bubble">
                    <h6 className="head-sol text-justify">
                     {item.faqQue}
                    </h6>
                    <p className="questions-sol text-justify">
                      {item.content}
                    </p>
                  </div>}</div>)
                })}

               
              </div>
            </section>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Solutions
